body {
  margin: 0;
  font-family: "Inter", Arial, sans-serif;
}

.no-scroll {
  overflow: hidden;
}

.content-width {
  width: calc(100% - 20px) !important;
}

@media (min-width: 640px) {
  .content-width {
    width: calc(100% - 100px) !important;
  }
}

@media (min-width: 1280px) {
  .content-width {
    width: 1100px !important;
  }
}

.first-video-main-page-height {
  max-width: 100%;
  max-height: calc(100vh - 100px);
}
@media (min-width: 500px) {
  .first-video-main-page-height {
    max-width: 85%;
  }
}

@media (min-width: 720px) {
  .first-video-main-page-height {
    max-width: 100%;
  }
}

.content-height {
  min-height: calc(100vh - 50px) !important;
}

.content-height-without-header {
  min-height: 100vh !important;
}

.fixed-preview-button-width {
  right: calc((100% - 1100px) / 2 - 100px);
}
